import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import Seo from "../../components/SEO";
import BlogSection from "../../containers/blog-section";

/* 
  Structure:
  -> Heading
  -> Filter by Tag
  -> Blog Cards
  -> Pagination
*/

// DONE: Query data of all mdx files
// TODO: Setup data for filtration and pagination
// TODO: Desktop Design
// TODO: Mobile Design

// States:
// 1. No Blog Post
// 2. Blog Post

export const query = graphql`
  query BLOG_QUERY_ALL {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        id
        frontmatter {
          slug
          title
          description
          series
          cover_src
          date(formatString: "Do MMMM, YYYY")
          read_time
        }
      }
      totalCount
    }
  }
`;

const BlogPage = ({
  data: {
    allMdx: { nodes: blogPosts },
  },
}) => {
  // console.log({ blogPosts });
  // const isMobile = !useMediaQuery(`(min-width: ${breakpoints.tablet} )`);

  return (
    <Layout>
      <Seo
        title="Blog"
        description="Contains all the blogs written by Parmeet Singh Asija"
      />
      <BlogSection blogPosts={blogPosts} />
    </Layout>
  );
};

export default BlogPage;
